import Sidebar from '@rio-cloud/rio-uikit/lib/es/Sidebar';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { FormattedMessage } from 'react-intl';
import { useDiagnosticsDetail } from '../hooks/useDiagnosticsDetail';
import DiagnosticsSidebarTabs from './DiagnosticsSidebarTabs';
import SidebarFooter from './SidebarFooter';
import CustomComponentDialog from '../customComponent/CustomComponentDialog';
import useCustomComponent from '../hooks/useCustomComponent';
import DeleteCustomComponentDialog from '../customComponent/DeleteCustomComponentDialog';
import useCustomComponentForDeletion from '../hooks/useCustomComponentForDeletion';
import { SidebarTitleAsset } from '../../../components/Asset';
import { Asset } from '../../../generated/fleetAggregationServiceTypes';
import { DiagnosticsNotBookedState } from './DiagnosticsNotBooked';
import { hasFeature } from '../../../productsConfig';
import VehiclePlanDialog from '../../../features/vehiclePlanDialog/VehiclePlanDialog';
import { useVehiclePlan } from '../../../features/vehiclePlanDialog/useVehiclePlan';
import InternalServerErrorState from '../../../components/InternalServerErrorState';
import { DiagnosticsSidebarAssetInformation } from './DiagnosticsSidebarAssetInformation';
import { FeatureToggle, isFeatureToggleEnabled } from '../../../hooks/useFeatureToggle';

type DiagnosticsSidebarProps = {
    reloadTrigger: object;
    tableReloadTriggerFunction: (assetId: Array<string>) => void;
    sidebarReloadTriggerFunction: (assetId: Array<string>) => void;
    assetId: string;
    onClose: (asset?: Asset) => () => void;
};

const DiagnosticsSidebar = ({ reloadTrigger, tableReloadTriggerFunction, sidebarReloadTriggerFunction, onClose, assetId }: DiagnosticsSidebarProps) => {
    const enableEngineOilDid = isFeatureToggleEnabled(FeatureToggle.ENABLE_ENGINE_OIL_DID);
    const { diagnosticsDetail, isLoading, error } = useDiagnosticsDetail(assetId, reloadTrigger, enableEngineOilDid);
    const { showCustomComponentDialog, closeDialog, openCustomComponentDialog, saveCustomComponent, initialCustomComponentState } = useCustomComponent([
        tableReloadTriggerFunction,
        sidebarReloadTriggerFunction,
    ]);

    const { deleteCustomComponent, showDeleteDialog, openDeleteDialog, closeDeleteDialog } = useCustomComponentForDeletion([
        tableReloadTriggerFunction,
        sidebarReloadTriggerFunction,
    ]);

    const mileage = diagnosticsDetail?.latest_events?.measured_at_distance;
    const { vehiclePlanDialogAsset, openVehiclePlanDialog, closeVehiclePlanDialog } = useVehiclePlan();

    return (
        <>
            <Sidebar
                fly={true}
                width="600px"
                title={
                    <>
                        <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.title" />
                        <SidebarTitleAsset asset={diagnosticsDetail?.asset} />
                    </>
                }
                showHeaderBorder={true}
                titleClassName="padding-left-10"
                onClose={onClose(diagnosticsDetail?.asset)}
                footer={
                    <SidebarFooter
                        assetId={assetId}
                        openCustomComponentDialog={openCustomComponentDialog}
                        asset={diagnosticsDetail?.asset}
                        openVehiclePlanDialog={openVehiclePlanDialog}
                    />
                }
            >
                {!error && !isLoading && diagnosticsDetail && (
                    <>
                        <div className="padding-left-20 padding-right-20">
                            <DiagnosticsSidebarAssetInformation diagnosticsDetail={diagnosticsDetail} />

                            <DiagnosticsSidebarTabs
                                components={diagnosticsDetail.components}
                                brake_linings={diagnosticsDetail.brake_linings}
                                engine_oil={diagnosticsDetail.engine_oil}
                                fill_levels={diagnosticsDetail.fill_levels}
                                tire_pressures={diagnosticsDetail.tire_pressures}
                                appointments={diagnosticsDetail.appointments}
                                openCustomComponentDialog={openCustomComponentDialog}
                                openDeleteCustomComponentDialog={openDeleteDialog}
                                asset={diagnosticsDetail.asset}
                                workshopName={diagnosticsDetail.assigned_workshop_name}
                            />
                            {!hasFeature('diagnosticDetailsView', diagnosticsDetail.asset) && <DiagnosticsNotBookedState assetId={diagnosticsDetail.asset.id} />}
                        </div>
                    </>
                )}

                {isLoading && (
                    <div className="height-100pct display-flex align-items-center justify-content-center">
                        <Spinner text={<FormattedMessage id="intl-msg:fleetstatus.global.loading" />} />
                    </div>
                )}
                {error && !isLoading && (
                    <InternalServerErrorState reloadTriggerFunction={sidebarReloadTriggerFunction.bind(null, [assetId])} className="margin-right-10 margin-left-10" />
                )}
            </Sidebar>
            {showCustomComponentDialog && (
                <CustomComponentDialog
                    show={showCustomComponentDialog}
                    closeDialog={closeDialog}
                    handleSave={saveCustomComponent}
                    defaultValue={initialCustomComponentState}
                    asset={diagnosticsDetail?.asset || ({} as Asset)}
                    mileage={mileage ? mileage : 0}
                />
            )}

            {showDeleteDialog && (
                <DeleteCustomComponentDialog
                    asset={diagnosticsDetail?.asset || ({} as Asset)}
                    deleteComponent={deleteCustomComponent}
                    closeDialog={closeDeleteDialog}
                    showDialog={showDeleteDialog}
                />
            )}

            <VehiclePlanDialog asset={vehiclePlanDialogAsset} closeDialog={closeVehiclePlanDialog} />
        </>
    );
};

export default DiagnosticsSidebar;
