import { useEffect, useState } from 'react';
import { DiagnosticDetail, Diagnostics } from '../../../generated/fleetAggregationServiceTypes';
import axios, { Canceler, CancelTokenSource } from 'axios';
import { useIntl } from 'react-intl';

type DiagnosticsDetailState = {
    diagnosticsDetail: DiagnosticDetail | null;
    isLoading: boolean;
    error: Error | null;
    axiosCanceler: Canceler | null;
};

export const useDiagnosticsDetail = (assetId: string, reloadTrigger: object, splitEngineOilLevel: boolean) => {
    const [diagnosticsDetailState, setDamageDetailState] = useState<DiagnosticsDetailState>({ diagnosticsDetail: null, isLoading: true, error: null, axiosCanceler: null });
    const intl = useIntl();

    useEffect(() => {
        const fetchDamageDetail = async () => {
            try {
                const params: Diagnostics.GetDiagnosticById.RequestQuery = { locale: intl.locale, split_engine_oil_level: splitEngineOilLevel };

                const newAxiosCancelTokenSource: CancelTokenSource = axios.CancelToken.source();
                setDamageDetailState((prevState) => {
                    if (prevState.axiosCanceler) {
                        prevState.axiosCanceler();
                    }
                    return { diagnosticsDetail: null, isLoading: true, error: null, axiosCanceler: newAxiosCancelTokenSource.cancel };
                });

                const res = await axios.get<DiagnosticDetail>(`${import.meta.env.VITE_AGGREGATION_SERVICE_URI}/diagnostics/${assetId}`, {
                    params,
                    cancelToken: newAxiosCancelTokenSource.token,
                });
                setDamageDetailState({ diagnosticsDetail: res.data, isLoading: false, error: null, axiosCanceler: null });
            } catch (e: any) {
                if (!axios.isCancel(e)) {
                    setDamageDetailState({ diagnosticsDetail: null, isLoading: false, error: e, axiosCanceler: null });
                }
            }
        };
        fetchDamageDetail();
    }, [intl.locale, assetId, reloadTrigger]);

    return { ...diagnosticsDetailState };
};
