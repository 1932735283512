import NotBookedState from '@rio-cloud/rio-uikit/lib/es/NotBookedState';
import { FeatureToggle, useFeatureToggle } from '../../../hooks/useFeatureToggle';
import { FormattedMessage } from 'react-intl';
import { isIAAVehicle } from './DiagnosticsSidebarTabs';

type DiagnosticsNotBookedStateType = {
    assetId: string;
}

export const DiagnosticsNotBookedState = ({assetId}: DiagnosticsNotBookedStateType) => {
    const { featureToggleValue: isHistoricalBatteryConditionsEnabled } = useFeatureToggle(FeatureToggle.ENABLE_HISTORICAL_BATTERY_CONDITIONS);

    const features = [
        <>
            <div className="padding-20">
                <FormattedMessage id={'intl-msg:fleetstatus.diagnostics.sidebar.no_booked_state.features.1'} />
            </div>
        </>,
        <>
            <div className="padding-20">
                <FormattedMessage id={'intl-msg:fleetstatus.diagnostics.sidebar.no_booked_state.features.2'} />
            </div>
        </>,
    ];

    if (isHistoricalBatteryConditionsEnabled || isIAAVehicle(assetId)) {
        features.push(
            <>
                <div className="padding-20">
                    <FormattedMessage id={'intl-msg:fleetstatus.diagnostics.sidebar.no_booked_state.features.3'} />
                </div>
            </>
        );
    }

    return (
        <div className="width-60pct center-block">
            <NotBookedState
                headline={<FormattedMessage id={'intl-msg:fleetstatus.diagnostics.sidebar.no_booked_state.headline.title'} />}
                message={<FormattedMessage id={'intl-msg:fleetstatus.diagnostics.sidebar.no_booked_state.headline.message'} />}
                features={features}
                condensed={true}
                className={'margin-bottom-20'}
            />
        </div>
    );
};
